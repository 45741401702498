import React from "react"

const Footer = () => (
  <footer className="footer">
    <div className="content has-text-centered">
      <p>&copy; Atherton Tableland Photography 2019</p>
    </div>
  </footer>
)

export default Footer
