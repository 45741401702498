import React from "react"
import brand from "../images/brand.png"
import { Link } from "gatsby"

const Header = class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active: false,
      navBarActiveClass: "",
    }
  }

  toggleHamburger = () => {
    // toggle the active boolean in the state
    this.setState(
      {
        active: !this.state.active,
      },
      // after state has been updated,
      () => {
        // set the class in state for the navbar accordingly
        this.state.active
          ? this.setState({
              navBarActiveClass: "is-active",
            })
          : this.setState({
              navBarActiveClass: "",
            })
      }
    )
  }

  render() {
    return (
      <nav
        className="navbar is-spaced is-transparent"
        role="navigation"
        aria-label="main navigation"
      >
        <div className="navbar-brand">
          <a className="navbar-item" href="/">
            <img alt="Tableland Photography" src={brand} />
          </a>

          <span
            role="button"
            className={`navbar-burger burger ${this.state.navBarActiveClass}`}
            data-target="navMenu"
            onClick={() => this.toggleHamburger()}
            aria-label="menu"
            aria-expanded="false"
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </span>
        </div>

        <div className="navbar-end">
          <div className="navbar-item">
            <div
              className={`navbar-menu ${this.state.navBarActiveClass}`}
              id="navMenu"
            >
              <div className="navbar-start">
                <Link className="navbar-item" to="/">
                  Home
                </Link>
                <div className="navbar-item has-dropdown is-hoverable">
                  {/* anchor tag without href isn't valid for screen readers, div has the same issue and also doesn't work in edge browser. */}
                  {/* <button id="button-navbar-link" className="navbar-link">Gallery</button> */}

                  <a className="navbar-link" // eslint-disable-line
                  >Gallery</a>
                  <div className="navbar-dropdown">
                    <Link className="navbar-item" to="/gallery/commercial">
                      Commercial
                    </Link>
                    <Link className="navbar-item" to="/gallery/portraits">
                      Portraits
                    </Link>
                    <Link className="navbar-item" to="/gallery/restoration">
                      Restoration
                    </Link>
                    <Link className="navbar-item" to="/gallery/schools">
                      Schools
                    </Link>
                    <Link className="navbar-item" to="/gallery/workshops">
                      Workshops
                    </Link>
                    <Link className="navbar-item" to="/gallery/landscape">
                      Landscape
                    </Link>
                  </div>
                </div>

                <Link className="navbar-item" to="/about/">
                  About
                </Link>
                <Link className="navbar-item" to="/contact/">
                  Contact
                </Link>
              </div>
            </div>
          </div>
        </div>
      </nav>
    )
  }
}
export default Header
